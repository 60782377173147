import { createSlice } from "@reduxjs/toolkit";


const initialState = {};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
});

export const { toggleAppendUuidToPdfFileDownloads } = settingsSlice.actions;

export default settingsSlice.reducer;