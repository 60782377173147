import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CircularProgress } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { generateWetlandPdf, generateWetlandPdfsFromSelection, selectPdfsDownloadStatusLoading } from "../../../api/services/filedownloads/fileDownloadsSlice";
import { selectSelectedWetlandFormIds, selectSelectedWetlandForms } from "../../../api/services/wetlandforms/wetlandFormsSlice";

export default function GeneratePDFsButton({ buttonText, useSmallInfoVariant }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectPdfsDownloadStatusLoading);
  const selectedFormIds = useSelector(selectSelectedWetlandFormIds);
  const selectedForms = useSelector(selectSelectedWetlandForms);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGeneratePdfs = (event, flattenOutput, include_photos=false) => {
    event.preventDefault();
    if (selectedFormIds.length === 1) {
      dispatch(generateWetlandPdf({ id: selectedFormIds[0], sampleName: selectedForms[0].sampleName, flattenOutput: flattenOutput, include_photos: include_photos }));
    } else {
      dispatch(generateWetlandPdfsFromSelection({ ids: selectedFormIds, flattenOutput: flattenOutput, include_photos: include_photos }));
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
    <Box sx={{ position: "relative" }}>
        <Button
          disabled={loading || !selectedFormIds.length}
          edge="end"
          aria-label="generate pdfs from selection"
          aria-controls={open ? 'generate-pdfs-options-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          color={useSmallInfoVariant ? "info" : null}
          size={useSmallInfoVariant ? "small" : null}
          disableElevation
          onClick={handleClick}
          startIcon={!useSmallInfoVariant && <PictureAsPdfIcon />}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {buttonText}
          {useSmallInfoVariant && <PictureAsPdfIcon />}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
    </Box>
    <Menu
      id="generate-pdfs-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'generate-pdfs-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={(e) => handleGeneratePdfs(e, false)} disableRipple>
        Fillable PDFs (editable fields)
      </MenuItem>
      <MenuItem onClick={(e) => handleGeneratePdfs(e, false, true)} disableRipple>
        Fillable PDFs with Appended Photos
      </MenuItem>
      <MenuItem onClick={(e) => handleGeneratePdfs(e, true)} disableRipple>
        Flattened PDFs (no editable fields; may solve PDF input field issues)
      </MenuItem>
      <MenuItem onClick={(e) => handleGeneratePdfs(e, true, true)} disableRipple>
        Flattened PDFs with Appended Photos
      </MenuItem>
    </Menu>
    </React.Fragment>
    
  )
}
